import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function MineEventDescription(props) {
    return (
        <>
            <div className="about-wrapper pt--120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 text-center text-lg-left">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Čarobna Litija in Rudnik Sitarjevec</h2>
                                    <p className="description">V okviru decembrskih dogodkov v mestu Litija, ki smo jih poimenovali Čarobna Litija, je
                                        pestro dogajanje tudi v in pred rudnikom Sitarjevec. Dogodki so lepo obiskani in vsako leto
                                        privabijo več obiskovalcev. Vabijo z drugačnostjo in unikatnostjo, nekateri pa z nostalgijo in
                                        prijetnim vzdušjem. .</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 mt-4 ">
                            <div className="thumbnail">
                                <StaticImage
                                    alt="Decembersko dogajanje v Rudniku Sitarjevec"
                                    src="../../assets/images/rudnik/december-rudnik-sitarjevec.jpeg"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pt--60 bg_color--1">
                <div className="container">
                    <div className="row row--35 align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12 order-1 order-lg-0 mt-4">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/mineralne-jaslice.jpeg"
                                    alt="Mineralne jaslice"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 order-0 order-lg-1">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Mineralne jaslice</h2>
                                    <p className="description">
                                        V dvorani rudnika si lahko obiskovalci v decembrskem času ogledajo edinstvene mineralne
                                        jaslice.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pt--60">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Pohod z baklami</h2>
                                    <p className="description">Tradicionalni pohod na soboto pred godom sv. Barbare, 4. decembra. Ob prižigu tisočerih lučk v Litiji škratki
                                        in Litko pričakajo pohodnike, ki se iz starega mestnega jedra v rudnik podajo z baklami.</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6">
                            <div className="thumbnail mt-4">
                                <StaticImage
                                    src="../../assets/images/rudnik/pohod-z-baklami.jpeg"
                                    alt="Pohod z baklami v Litiji meseca decembra"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pt--60 pb--120">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12 order-1 order-lg-0 mt-4">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/zive-jaslice.jpeg"
                                    alt="Zive jaslice"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-xl-6 order-0 order-lg-1">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Žive jaslice</h2>
                                    <p className="description">
                                        So še ena od posebnosti decembrskega dogajanja v Litiji. Božično zgodbo si lahko
                                        obiskovalci ogledajo na platoju pred vhodom v rudnik.«.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MineEventDescription;